import ObserverVisibility from 'components/common/ObserverVisibility';
import Link from 'next/link';
import styles from './styles.module.scss';

interface Props {
  threshold?: number;
  titleHTML: string;
  titleHref?: string;
  descHTML: string;
}

export default (props: Props) => {
  const { threshold = 1, titleHTML, titleHref, descHTML } = props;

  return (
    <ObserverVisibility threshold={threshold}>
      {(visibility, ref) => {
        return (
          <div ref={ref} className="lg:px-0 xl:w-[32rem]">
            {visibility && (
              <>
                <div className={`${styles.animate} ${styles.titleBox}`}>
                  {titleHref ? (
                    <Link href={titleHref}>
                      <h2
                        className="mb-5 text-3xl font-bold leading-[1.2] text-black lg:text-[44px]"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: titleHTML }}
                      ></h2>
                    </Link>
                  ) : (
                    <h2
                      className="mb-5 text-3xl font-bold leading-[1.2] text-black lg:text-[44px]"
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{ __html: titleHTML }}
                    ></h2>
                  )}
                  <p
                    className="mb-10 text-lg leading-normal"
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{ __html: descHTML }}
                  ></p>
                </div>
              </>
            )}
          </div>
        );
      }}
    </ObserverVisibility>
  );
};

import Image, { StaticImageData } from 'next/image';
import classnames from 'classnames';
import TitleBox from '../TitleBox';
import Button from '../../../index/Button';

export default ({
  title,
  titleHref,
  description,
  imgSrc,
  reverse = false,
}: {
  title: string;
  titleHref?: string;
  description: string;
  imgSrc: StaticImageData;
  reverse?: boolean;
}) => {
  return (
    <div className={classnames('px-5 py-[3.125rem]', 'lg:py-[6.5625rem]')}>
      <div
        className={classnames(
          'mx-auto flex flex-col items-center justify-between lg:w-[1200px]',
          reverse ? 'lg:flex-row-reverse' : 'lg:flex-row'
        )}
      >
        <div>
          <TitleBox titleHTML={title} titleHref={titleHref} descHTML={description} />
          <Button color="black" position="app-info" link="https://app.adjust.com/1b2ooro2">
            {$t({ defaultMessage: 'Free Download', id: '6x1fBa' })}
          </Button>
        </div>
        <Image
          src={imgSrc}
          alt={title}
          className="mt-10 h-auto w-full rounded-3xl lg:mx-0 lg:h-[590px] lg:w-[590px]"
        ></Image>
      </div>
    </div>
  );
};

import { useRef } from 'react';
import Link from 'next/link';
import classnames from 'classnames';
import useIntersectionObserver from '../../../hooks/useIntersectionObserver';
import { downloadReportClick, downloadReportExposure } from '../../../utils/downloadReport';

export const Button = ({
  children,
  link,
  color = 'orange',
  position = 'homepage',
  className = '',
  target = '_blank',
  linkFollow = false,
  title = 'Free Download',
}: {
  children?: React.ReactNode;
  link: string;
  full?: boolean;
  color?: 'default' | 'orange' | 'black' | 'white';
  position?: string;
  className?: string;
  target?: string;
  linkFollow?: boolean;
  title?: string;
}) => {
  const ref = useRef<HTMLAnchorElement>(null);

  useIntersectionObserver({
    ref,
    callback: () => {
      downloadReportExposure({
        position,
      });
    },
  });

  return (
    <Link
      href={link}
      ref={ref}
      rel={linkFollow ? undefined : 'nofollow'}
      target={target}
      title={title || 'Free Download'}
      className={classnames(
        `flex h-[15.89vw] w-fit items-center justify-center text-nowrap rounded-full px-[1.875rem] py-[0.625rem] text-[5.12vw] text-xl lg:h-[62px] lg:px-[30px] lg:py-[10px]`,
        // eslint-disable-next-line no-nested-ternary
        color === 'orange'
          ? `bg-ab-orange text-white hover:bg-[#FF6B19]`
          : color === 'white'
          ? 'bg-white text-black hover:bg-black hover:text-white'
          : 'bg-black text-white hover:border hover:border-black hover:bg-white hover:font-medium hover:text-black',
        className
      )}
      onClick={() => {
        downloadReportClick({
          position,
        });
      }}
    >
      {children}
    </Link>
  );
};

export default Button;

import { useState, useEffect, useRef, useCallback } from 'react';

export default function ObserverVisibility({ children, rootMargin = '0px', threshold = 0.5 }) {
  const [visibility, setVisibility] = useState(false);

  const ref = useRef<HTMLElement>();

  const createObserver = useCallback(() => {
    const options = {
      root: null,
      rootMargin,
      threshold, // 元素和屏幕的交叉比例
    };

    const observerObj = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          observer.unobserve(entry.target);
          setVisibility(true);
        }
      });
    }, options);
    observerObj.observe(ref.current);
  }, [rootMargin, threshold]);

  useEffect(() => {
    createObserver();
  }, [createObserver]);

  return children(visibility, ref);
}

import Image from 'next/image';

import Button from '../../../index/Button';
import Cart from './img/cart.svg';
import Champion from './img/champion.svg';

export default () => {
  return (
    <div className="bg-[#F6F6F6] px-5 py-[3.125rem] lg:py-[80px]">
      <div>
        <h2 className="mx-auto text-center text-3xl font-bold leading-[1.2] lg:max-w-[1200px] lg:text-[44px]">
          {$t({ defaultMessage: 'Why Do You Need AirBrush AI Photo Editing App?', id: 'IRO3eX' })}
        </h2>
        <div className="mx-auto mt-10 flex flex-col justify-between gap-5 lg:w-[1200px] lg:flex-row">
          <div className="rounded-[20px] bg-white px-5 py-10 lg:flex lg:gap-[30px] lg:p-[40px]">
            <Image
              src={Champion}
              alt={$t({
                defaultMessage: '"Photo Editing App Download AirBrush App"',
                id: '/Z/UBH',
              })}
              className="h-20 w-20"
            />
            <div className="mt-5 lg:mt-0">
              <h3 className="text-[1.5rem] font-bold leading-[1.2]">
                {$t({
                  defaultMessage: 'Best Free Picture Editing App For Social Media',
                  id: 'jvJonz',
                })}
              </h3>
              <p className="mt-5 text-base">
                {$t({
                  defaultMessage:
                    'AirBrush is the ultimate picture editing app for social media. Enhance images, apply filters, and perfect your look with AI tools. Create stunning, share-worthy photos directly from your smartphone.',
                  id: 'RQSJje',
                })}
              </p>
            </div>
          </div>
          <div className="rounded-[20px] bg-white px-5 py-10 lg:flex lg:gap-[30px] lg:p-[40px]">
            <Image
              src={Cart}
              alt={$t({
                defaultMessage: '"Photo Editing App Download AirBrush App"',
                id: '/Z/UBH',
              })}
              className="h-20 w-20"
            />
            <div className="mt-5 lg:mt-0">
              <h3 className="text-[1.5rem] font-bold leading-[1.2]">
                {$t({
                  defaultMessage: 'AI Image Enhancer App for E-Commerce Store',
                  id: 'xKPMWc',
                })}
              </h3>
              <p className="mt-5 text-base">
                {$t({
                  defaultMessage:
                    'Boost your e-commerce store with AirBrush AI Image Enhancer. Improve product photos, enhance details, adjust lighting, and remove imperfections to create professional-quality images that drive sales.',
                  id: 'ea6FeB',
                })}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-[3.75rem] flex justify-center">
          <Button link="https://app.adjust.com/1b2ooro2" color="orange" position="app-info">
            {$t({ defaultMessage: 'Free Download', id: '6x1fBa' })}
          </Button>
        </div>
      </div>
    </div>
  );
};

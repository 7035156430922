/* eslint-disable react/no-danger */
import Head from 'next/head';
import Intro from 'components/tools/app/Intro';
import Section from 'components/tools/app/Section';
import Why from 'components/tools/app/Why';
import Ratings from 'components/index/Ratings';
import FAQ from 'components/index/FAQ';

import { useEffect, useState } from 'react';
import img2 from './img/app/img_2.jpg';
import img3 from './img/app/img_3.jpg';
import img4 from './img/app/img_4.jpg';
import img5 from './img/app/img_5.jpg';
import img6 from './img/app/img_6.jpg';
import img7 from './img/app/img_7.jpg';

export default () => {
  const [url, setUrl] = useState('');

  useEffect(() => {
    setUrl(window.location.href);
  }, []);

  const pageTitle = $t({
    defaultMessage: 'Online Photo Retouch | AI Photo Retouching App',
    id: 'hC3FUC',
  });

  const pageDesc = $t({
    defaultMessage:
      "Effortlessly enhance your photos with AirBrush's Photo Retouch tool. Smooth skin, remove blemishes, whiten teeth, and more for flawless, professional-quality images.",
    id: '4+CcM8',
  });

  const pageKw = $t({
    defaultMessage: 'Photo Retouch',
    id: 'oih2xB',
  });

  const imageAlt = $t({
    defaultMessage: '"Photo Retouch AirBrush photo retouching"',
    id: 'wrJ3MS',
  });

  const imageUrl =
    'https://blog.airbrush.com/wp-content/uploads/2024/07/AirBrush-App-AI-Photo-Editor.jpg';

  const whatList = [
    {
      title: $t({ defaultMessage: 'Try The Best App For Editing Photos', id: 'LnFNsK' }),
      titleHref: '../',
      description: $t({
        defaultMessage:
          'AirBrush App offers top-notch image and video editing features, including AI tools like headshot generators, AI filters, background removers, and more. Enhance image quality effortlessly with advanced editing tools directly on your smartphone.',
        id: 'PCGN6X',
      }),
      imgSrc: img2,
      reverse: false,
    },
    {
      title: $t({ defaultMessage: 'AirBrush Photo Retouching App', id: 'aOWwtR' }),
      titleHref: '../retouch',
      description: $t({
        defaultMessage:
          'AirBrush App is the ultimate tool for online makeup editing and photo retouching. Easily apply makeup, smooth skin, remove blemishes, and enhance features with precision. Refine your images and videos with cutting-edge AI technology right from your smartphone.',
        id: 'Cpe98/',
      }),
      imgSrc: img3,
      reverse: true,
    },
    {
      title: $t({
        defaultMessage: 'AirBrush Blur Remover and Background Remover App',
        id: 'BVhxHA',
      }),
      titleHref: '../removebg',
      description: $t({
        defaultMessage: `AirBrush lets you effortlessly '<a href="../eraser">remove unwanted objects, passengers,</a>' blur backgrounds, enhance your photos for a sharp, professional look. Perfect for creating stunning visuals right from your smartphone.`,
        id: 'UYjZNQ',
      }),
      imgSrc: img4,
      reverse: false,
    },
    {
      title: $t({
        defaultMessage: 'AirBrush Makeup Editor',
        id: 'FHrd9Y',
      }),
      description: $t({
        defaultMessage:
          'Enhance your selfies with AirBrush Makeup Editor. Easily apply virtual makeup, smooth skin, remove blemishes, acne, and red eye. Achieve flawless, professional-quality edits right from your smartphone.',
        id: 'HYKiC4',
      }),
      imgSrc: img5,
      reverse: true,
    },
    {
      title: $t({
        defaultMessage: 'AirBrush Photo Enhancer App',
        id: 'lE12jA',
      }),
      titleHref: '../image-enhancer',
      description: $t({
        defaultMessage:
          'Effortlessly enhance your photos with the AirBrush Photo Enhancer App. Improve image quality, refine facial features, and correct imperfections. Achieve stunning, professional-quality edits right from your smartphone.',
        id: 'nSZ+st',
      }),
      imgSrc: img6,
      reverse: false,
    },
    {
      title: $t({
        defaultMessage: 'AirBrush Passport Photo Editor',
        id: 'XrRD3o',
      }),
      description: $t({
        defaultMessage:
          "AirBrush's Passport Photo Editor App ensures perfect passport photos with ease. Use advanced tools to adjust size, background, and lighting for professional-quality results. Edit and print your passport photos conveniently from your smartphone.",
        id: 'Hi/wRp',
      }),
      imgSrc: img7,
      reverse: true,
    },
  ];

  const faqList = [
    {
      question: $t({ defaultMessage: 'Is the AirBrush App free to download?', id: 'kOvYjs' }),
      answer: $t({
        defaultMessage:
          'Yes, the AirBrush Photo Editing App is free to download. It offers a variety of free features, with additional premium features available through in-app purchases for enhanced editing capabilities.',
        id: '1rVpeJ',
      }),
    },
    {
      question: $t({
        defaultMessage: 'What makes AirBrush the best photo enhancer app?',
        id: 'eKkj1x',
      }),
      answer: $t({
        defaultMessage:
          'AirBrush stands out as the best photo enhancer app due to its powerful AI tools, user-friendly interface, and professional-quality results. It offers features like smoothing, lighting adjustments, and blemish removal.',
        id: 'WNevTu',
      }),
    },
    {
      question: $t({
        defaultMessage: 'Can I use AirBrush as a selfie editor?',
        id: '0eNts+',
      }),
      answer: $t({
        defaultMessage:
          'Yes, AirBrush is an excellent selfie editor. You can enhance your selfies with features like virtual makeup, skin smoothing, and red-eye removal to achieve flawless photos.',
        id: 'Bp0CKY',
      }),
    },
    {
      question: $t({
        defaultMessage: 'Does AirBrush offer AI photo filters?',
        id: '5A4Bw9',
      }),
      answer: $t({
        defaultMessage:
          'Absolutely! AirBrush Photo Editing App includes advanced AI photo filters that automatically enhance your images by adjusting colors, brightness, and contrast for a stunning, professional look.',
        id: 'yBzMkU',
      }),
    },
    {
      question: $t({
        defaultMessage: 'Can I use AirBrush as a beauty photo editor online?',
        id: 'OY2DHY',
      }),
      answer: $t({
        defaultMessage:
          'Definitely! AirBrush functions as a beauty photo editor online, enabling you to edit your images through your web browser without needing to Download AirBrush App or any software.',
        id: 'qcpKJU',
      }),
    },
    {
      question: $t({
        defaultMessage: 'What features does the AirBrush Makeup App offer?',
        id: 'W0KfLX',
      }),
      answer: $t({
        defaultMessage:
          'The AirBrush Makeup App offers a variety of features, including virtual makeup application, skin smoothing, blemish removal, and photo retouching to enhance your photos beautifully.',
        id: 'QiEiDK',
      }),
    },
    {
      question: $t({
        defaultMessage: 'How can AirBrush help as a photo fixer?',
        id: '3JBUZj',
      }),
      answer: $t({
        defaultMessage:
          'AirBrush helps as a photo fixer by providing tools to correct common photo issues like poor lighting, red-eye, and blemishes, ensuring your photos look their best.',
        id: '6Sq44i',
      }),
    },
  ];

  return (
    <>
      <Head>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDesc} />
        <meta name="keywords" content={pageKw} />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1.0,maximum-scale=1.0,minimum-scale=1.0,user-scalable=no,viewport-fit=cover"
        />

        {/* Hreflangs */}
        <link rel="alternate" href="https://airbrush.com/tools/app" hrefLang="X-default" />
        <link rel="alternate" href="https://airbrush.com/tools/app" hrefLang="en" />
        <link rel="alternate" href="https://airbrush.com/pt/tools/app" hrefLang="pt" />
        <link rel="alternate" href="https://airbrush.com/es/tools/app" hrefLang="es" />
        <link rel="alternate" href="https://airbrush.com/ru/tools/app" hrefLang="ru" />
        <link rel="alternate" href="https://airbrush.com/de/tools/app" hrefLang="de" />
        <link rel="alternate" href="https://airbrush.com/fr/tools/app" hrefLang="fr" />

        {/* Canonical标签 */}
        <link rel="canonical" href={url} />

        {/* Facebook social share info */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDesc} />
        <meta property="og:url" content={url} />
        <meta property="og:site_name" content="AirBrush" />
        <meta property="og:image:alt" content={imageAlt} />
        <meta property="og:image" content={imageUrl} />
        <meta property="og:image:secure_url" content={imageUrl} />
        <meta property="og:image:type" content="image/jpeg" />
        <meta property="og:image:width" content="400" />
        <meta property="og:image:height" content="300" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:site" content="@AirBrushApp" />
        <meta name="twitter:description" content={pageDesc} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content={imageUrl} />

        {/* google search console */}
        <meta
          name="google-site-verification"
          content="V6DRGbgFCB7BvX7P_Ki06hta1d1xDLndH3oD6OtbgTw"
        />

        {/* trustpilot验证 */}
        <meta
          name="trustpilot-one-time-domain-verification-id"
          content="eb677ca8-be3e-4cf4-86bc-f4c51c17d92f"
        />

        {/* Structured 结构化数据 */}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `{
              "@context": "https://schema.org/",
              "@type": "BreadcrumbList",
              "itemListElement": [
                {
                  "@type": "ListItem",
                  "position": "1",
                  "name": "Home",
                  "item": "https://airbrush.com/tools/app"
                },
                {
                  "@type": "ListItem",
                  "position": "2",
                  "name": "${pageTitle}",
                  "item": "https://airbrush.com/tools/app"
                }
              ]
            }`,
          }}
        ></script>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `{
            "@context": "https://schema.org/",
            "@type": "Organization",
            "url": "https://airbrush.com/tools/app",
            "logo": "https://blog.airbrush.com/wp-content/uploads/2024/05/AB-logo-150150.png",
            "name": "${$t({ defaultMessage: 'AirBrush', id: '47CPPy' })}",
            "image": "${imageUrl}",
            "brand": {
              "@type": "Organization"
            },
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": 4.8,
              "ratingCount": "1727952",
              "reviewCount": 1727952,
              "bestRating": 5,
              "worstRating": 1
            },
            "legalName": "${$t({
              defaultMessage: 'Pixocial Technology (Singapore) Ltd. Pte',
              id: 'MdT1ts',
            })}"
          }`,
          }}
        ></script>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `{
            "@context": "https://schema.org/",
            "@type": "WebSite",
            "name": "${pageTitle}",
            "url": "https://airbrush.com/tools/app",
            "sameAs": [
              "https://www.facebook.com/airbrushappofficial",
              "https://twitter.com/AirBrushApp",
              "https://www.pinterest.com/airbrushofficial/",
              "https://www.tiktok.com/@airbrushofficial",
              "https://www.instagram.com/airbrushofficial/"
            ],
            "headline": "${pageTitle}",
            "description": "${pageDesc}",
            "image": "${imageUrl}"
          }`,
          }}
        ></script>
      </Head>
      <div className="overflow-x-hidden bg-white">
        <Intro />
        {whatList.map((what, index) => (
          <Section {...what} key={index} />
        ))}
        <Why />
        <Ratings />
        <FAQ list={faqList} />
      </div>
    </>
  );
};

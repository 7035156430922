import { useState } from 'react';
import styles from './styles.module.scss';

export interface IFAQ {
  question: string;
  answer: string;
}

const FAQ = ({ faq, index }: { faq: IFAQ; index: number }) => {
  const [show, setShow] = useState(index === 0);
  return (
    <div className="py-5">
      <div className="flex items-center" onClick={() => setShow(!show)}>
        <h3 className="flex-1 text-base font-semibold lg:text-[22px] lg:leading-normal">
          {faq.question}
        </h3>
        <div className={`${show ? styles.hide : styles.show} h-3 w-3`}></div>
      </div>
      {show && <p className="mt-4 text-xs leading-normal opacity-80 lg:text-base">{faq.answer}</p>}
    </div>
  );
};

export default ({ list }: { list: IFAQ[] }) => {
  return (
    <div className="bg-black px-5 py-[3.125rem] text-white lg:py-[80px]">
      <div className="mx-auto lg:max-w-[1200px]">
        <h2 className="text-center text-3xl font-bold leading-normal lg:text-[44px] lg:leading-[1.2]">
          {$t({ defaultMessage: 'Frequently Asked Questions', id: 'QA1wrZ' })}
        </h2>
        <div className="mt-5 lg:mt-[50px]">
          {list.map((faq, i) => (
            <FAQ faq={faq} key={i} index={i} />
          ))}
        </div>
      </div>
    </div>
  );
};

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { useCallback, useEffect, useRef, useState } from 'react';
import CfImage from 'components/common/CfImage';
import { ReactSVG } from 'react-svg';
import classnames from 'classnames';
import usePlatform from '../../../hooks/usePlatform';
import styles from './styles.module.scss';

interface Props {
  content: string;
  user: string;
  className?: string;
}

const SingleRating = (props: Props) => {
  const { content, user } = props;
  const { platform } = usePlatform();

  return (
    <div
      className={`${styles.singleRating} ${
        platform === 'iOS' || platform === 'Android' ? '' : 'swiper-no-swiping'
      } flex h-full w-[19.375rem] items-center rounded-3xl px-5 py-10 lg:w-[384px] lg:py-[30px]`}
    >
      <div className="flex h-full flex-col justify-between">
        <div className="flex">
          <div className="mr-4 basis-[28px]">
            <CfImage
              src="https://gcs.beautyplus.com/2802aaac384aaf2f0ecaad0b32776e47.png"
              width={28}
              alt="Ratings-decorate"
            />
          </div>
          <p className="mb-6 w-[14.5rem] text-base text-white lg:mb-[20px] lg:w-[19.375rem]">
            {content}
          </p>
        </div>
        <div className="flex justify-between pl-10">
          <CfImage
            src="https://gcs.beautyplus.com/5af74a1a519e414349330f04af46f064.png"
            width={102}
            alt="Ratings-star"
          />
          <p className="font-SemiBold text-white">{`- ${user}`}</p>
        </div>
      </div>
    </div>
  );
};

interface ratingProps {
  title?: string;
  desc?: string;
  list?: Array<{
    content: string;
    user: string;
  }>;
}

export default (props: ratingProps) => {
  const {
    title = $t({ defaultMessage: 'User Reviews', id: '/UsqKg' }),
    desc = $t({ defaultMessage: '172.8K Ratings', id: 'Pl9gLN' }),
    list = [
      {
        content: $t({
          defaultMessage:
            'AirBrush is fantastic! I love how easy it is to use. The AI photo editor made my pictures look amazing with just one click. Highly recommend this app for anyone who wants quick and beautiful edits.',
          id: 'U4VHZ3',
        }),
        user: $t({ defaultMessage: 'Emily S.', id: 'DSftQN' }),
      },
      {
        content: $t({
          defaultMessage:
            'I’ve tried many editing apps, but AirBrush is by far the best. The background remover and object remover tools are so precise. My photos look flawless every time. Definitely worth downloading!',
          id: 'b23r8M',
        }),
        user: $t({ defaultMessage: 'Anna L.', id: 'qAB4Xb' }),
      },
      {
        content: $t({
          defaultMessage:
            'I love using the AI Avatar Generator. It turned my simple selfies into cool and stylish avatars. The process is quick, and the results are high-quality.',
          id: 'zNFhFa',
        }),
        user: $t({ defaultMessage: 'Brian C.', id: 'DPfYRD' }),
      },
    ],
  } = props;

  const ref = useRef<HTMLDivElement | null>(null);
  const [left, setLeft] = useState(0);
  const swiperRef = useRef(null);
  const [reached, setReached] = useState<-1 | 0 | 1>(-1);

  const handler = useCallback(() => {
    if (ref?.current) {
      const left = ref?.current?.getBoundingClientRect?.()?.x;
      if (left) {
        setLeft(left);
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handler);

    handler();

    return () => {
      window.removeEventListener('resize', handler);
    };
  }, [handler]);

  return (
    <div
      className={classnames(
        'flex h-auto items-center justify-center overflow-hidden bg-white py-[3.125rem] lg:py-[106px]',
        list.length > 3 ? 'lg:pb-[206px]' : ''
      )}
    >
      <div className="h-full w-full">
        <div className="relative w-full px-5 lg:mx-auto lg:max-w-[1200px] lg:px-8">
          <p
            className="mb-2.5 text-lg font-medium uppercase text-ab-orange lg:text-[22px]"
            ref={ref}
          >
            {desc}
          </p>
          <h3 className="mb-[1.875rem] max-w-3xl text-[1.875rem] font-bold lg:mb-10 lg:text-5xl lg:leading-[56px]">
            {title}
          </h3>
        </div>
        <div className="relative">
          <div className="invisible relative z-0 flex flex-nowrap">
            {list.map((item, index) => {
              return (
                <div className="w-[19.375rem]" key={index}>
                  <SingleRating {...item} />
                </div>
              );
            })}
          </div>
          <Swiper
            spaceBetween={20}
            style={{
              height: '100%',
              width: '100%',
              top: 0,
              left: 0,
              zIndex: 1,
              position: 'absolute',
              paddingLeft: `${left}px`,
              paddingRight: `${left}px`,
            }}
            ref={swiperRef}
            slidesPerView="auto"
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
            }}
            onActiveIndexChange={(Swiper) => {
              setTimeout(() => {
                console.log(Swiper);
                if (Swiper.isBeginning) {
                  setReached(-1);
                } else if (Swiper.isEnd) {
                  setReached(1);
                } else {
                  setReached(0);
                }
              }, 500);
            }}
          >
            {list.map((item, index) => {
              return (
                <SwiperSlide key={index} style={{ width: 'auto', height: '100%' }}>
                  <SingleRating {...item} />
                </SwiperSlide>
              );
            })}
          </Swiper>
          {list.length > 3 && swiperRef.current && (
            <div className="absolute hidden w-auto lg:-bottom-[80px] lg:left-[50%] lg:flex lg:translate-x-[477px]">
              <div
                className={classnames(
                  'mr-4 flex h-12 w-12 cursor-pointer select-none items-center justify-center rounded-full border border-solid border-black',
                  reached === -1 ? 'border-[#d7d7d7]' : ''
                )}
                onClick={() => {
                  if (list.length === 4) {
                    swiperRef.current?.slidePrev();
                    swiperRef.current?.slidePrev();
                  } else {
                    swiperRef.current?.slidePrev();
                  }
                }}
              >
                <ReactSVG src="https://gcs.beautyplus.com/cc5cd9cbdc8957412bffd8ebc81fd716.svg" />
              </div>
              <div
                className={classnames(
                  'h-12| flex w-12 rotate-180 cursor-pointer select-none items-center justify-center rounded-full border border-solid border-black',
                  reached === 1 ? 'border-[#d7d7d7]' : ''
                )}
                onClick={() => {
                  if (list.length === 4) {
                    swiperRef.current?.slideNext();
                    swiperRef.current?.slideNext();
                  } else {
                    swiperRef.current?.slideNext();
                  }
                }}
              >
                <ReactSVG src="https://gcs.beautyplus.com/cc5cd9cbdc8957412bffd8ebc81fd716.svg" />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
